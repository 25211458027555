import { Card, Text, Group, Stack, IconName, Icon, MantineColor } from 'ui/core'
import Link from 'next/link'
import { ReactNode } from 'react'

interface DashboardItemProps {
  title: ReactNode,
  count?: ReactNode,
  href: string,
  color?: MantineColor,
  iconName: IconName,
}

const DashboardItem = ({ title, count, href, iconName, color }: DashboardItemProps) => (
  <Card
    data-testid="card"
    py={48}
    component={Link}
    href={href}
    style={{ backgroundColor: color }}
  >
    <Group justify="center">
      <Icon
        testID="icon"
        name={iconName}
        color="white"
        size={48}
      />
      <Stack gap="xs">
        {count && (
          <Text
            data-testid="count"
            inline
            color="white"
            size="xl"
            fw={700}
          >
            {count}
          </Text>
        )}
        <Text
          data-testid="title"
          inline
          color="white"
          size="xl"
        >
          {title}
        </Text>
      </Stack>
    </Group>
  </Card>
)

export default DashboardItem
