import DashboardItem from 'components/DashboardItem'
import { Page, SectionHeader, Tumbleweed } from 'core/components'
import { useQuerySellRequests, SellRequestCard, SellRequestCardLoading } from '@resellam/sell-request'
import { useAuth } from '@resellam/auth'
import Link from 'next/link'
import { useMemo } from 'react'
import { Button, Icon, SimpleGrid, Stack, useMantineTheme } from 'ui/core'
import { getItems } from 'utils/menu-items'
import { useApi, useIsLoading } from 'core/hooks'
import { useCountDocuments } from '@resellam/sanity'

const HomePage = () => {
  const theme = useMantineTheme()
  const auth = useAuth()
  const { data: brandsCount } = useCountDocuments('brand')
  const { data: productsCount } = useCountDocuments('product')
  const { data: categoriesCount } = useCountDocuments('category')
  const { data: usersCount } = useApi<{ data: { count: number } }>(auth.user ? '/users/count' : undefined)
  const { data: sellRequests, isLoading: isLoadingSellRequests } = useQuerySellRequests({
    limit: 5,
    orderBy: useMemo(() => [{ field: 'createdAt', direction: 'desc' }], []),
    where: useMemo(() => [{ field: 'status.name', operator: '==', value: 'SUBMITTED' }], []),
  })
  const isLoading = useIsLoading({
    dependencies: [isLoadingSellRequests],
  })

  const countsMap: Record<string, number | undefined> = {
    brand: brandsCount,
    product: productsCount,
    category: categoriesCount,
    user: usersCount?.data.count,
  }

  return (
    <Page title="Dashboard">
      <Stack>
        <SimpleGrid cols={{ base: 1, sm: 2 }}>
          {getItems(theme.colors).map(({ key, ...item }) => (
            <DashboardItem {...item} count={countsMap[key]} key={key} />
          ))}
        </SimpleGrid>
        <div>
          <SectionHeader
            title="Requests"
            action={(
              <Button
                size="compact-md"
                component={Link}
                href="/requests"
                variant="subtle"
                rightSection={<Icon name="arrowRight" />}
              >
                See all
              </Button>
            )}
          />
          <Stack gap="sm">
            {isLoading
            && [...new Array(5).keys()].map((i) => <SellRequestCardLoading compact key={i} />)}
            {!isLoading && sellRequests?.length
              ? sellRequests?.map((sellRequest) => (
                <SellRequestCard
                  compact
                  sellRequest={sellRequest}
                  component={Link}
                  key={sellRequest.id}
                  href={`/requests/${sellRequest.id}`}
                />
              ))
              : null}
          </Stack>
          {!isLoading && !sellRequests?.length ? (
            <Tumbleweed variant="card" tumbleweedContent="requests" />
          ) : null}
        </div>
      </Stack>
    </Page>
  )
}

export default HomePage
